// ** React Imports
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';

import styles from './auth.module.scss';

// ** Images
import logo from "../../assets/img/logo.png";
import imgLogin from "../../assets/img/login.jpg";

function AuthLayout() {

  return (
    <Container fluid>
      <Row className="d-flex align-items-center">
        <Col md="12" lg="6">
          <div className={`${styles.blocoDados} d-flex align-items-center`}>
            <div className="w-100">
              <div className="text-center pb-4">
                <img src={logo} width="100px" alt="" />
              </div>
              <Outlet />
            </div>
          </div>
        </Col>
        <Col className="px-0 d-none d-lg-block" md="12" lg="6">
          <div
            className={styles.bannerAuto}
            style={{ backgroundImage: `url(${imgLogin})` }}
          ></div>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthLayout