import { NavItem, NavLink, Nav } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import styles from './menu.module.scss';

import menuList from '../../menu';

function Menu() {

  const navigate = useNavigate();

  const menu = menuList;

  const handleItemClick = (url: string) => {
    navigate(url);
  }

  return (
    <>
      <Nav className={`${styles.menu} nav justify-content-end`}>
        {menu.map((item, index)=>(
        <NavItem key={index}>
          <NavLink className={`${styles.menuLink}`} onClick={() => handleItemClick(item.url)}>
            {item.nome}
          </NavLink>
        </NavItem>
        ))}
      </Nav>
    </>
  );
}

export default Menu;
