import { useState, useEffect } from "react";
import {
  Container, Row, Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from "react-router-dom";

import styles from './menuuser.module.scss';

import request from "../../services/api";
import { deleteToken, getToken, deleteUser, getUser, userTipo } from "../../services/auth";

function MenuUser({ direction, ...args }: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [diasPreenchidos, setDiasPreenchidos]: any = useState(0);
  const navigate = useNavigate()
  const token: string = getToken() || '';

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function logout() {
    deleteToken()
    deleteUser()
    navigate("/login")
  }

  const user: any = getUser();
  const cidadeId = user.cidades.length > 0 ? user.cidades[0].id : "";

  useEffect(() => {
    request<{ message: string }>({
      method: 'get',
      endpoint: `/relatorios?dataIni=2023-01-01&dataFim=2023-12-31&cidadeId=${cidadeId}`,
      token,
    }).then((res: any) => {
      console.log(res.pendentesRelatorio.intervaloProximoMes);
      setDiasPreenchidos(res.pendentesRelatorio.intervaloProximoMes);
    }).catch((error: any) => {
      console.log(error)
    });
  }, []);

  return (
    <>
      <div className={`py-4 bg-primary ${styles.topoUser}`}>
        <Container className='position-relative'>
          <Row className='d-flex align-items-center'>
            <Col className='mb-4 mb-lg-0' lg="6">
              <h2 className="font-16 fw-bold text-white">Olá, {user.username}!</h2>
              <h3 className='text-white font-14 mb-0 fw-bold'>Minhas informações</h3>
              <div className="font-12 text-white">
                {user.cidades.map((item: any, key: string) => (
                  <div key={key}>
                    {item.cidade} - {item.regioes.length > 0 ? item.regioes[0].regiao : ""}
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="6">
              <Row className="w-100 d-flex justify-content-end">
                <Col className={`mb-3 mb-lg-0 ${diasPreenchidos !== 0 && diasPreenchidos < 10 ? "" : "d-none"}`} sm="6" md="6" lg="10">
                  <div className='w-100'>
                    <div className="row d-flex align-items-center">
                      <div className="col-1">
                        <FontAwesomeIcon className='text-white font-22' icon={solid("bell")} />
                      </div>
                      <div className="col-11">
                        <div className='font-14 mb-1 text-white fw-bold'>Faltam {10 - diasPreenchidos} dias para você atualizar seus dados.</div>
                        <div className='font-14 fw-bold text-secondary'>Acesse o link empresa para acessar as informações!</div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="2">
                  <div className='text-end'>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                      <DropdownToggle className={`btn-circle ${styles.dropdownToggle}`} color="white" caret><FontAwesomeIcon className='text-secondary' icon={solid("user")} /></DropdownToggle>
                      <DropdownMenu {...args}>
                        <DropdownItem onClick={() => navigate("/meus-dados")}>Meus dados</DropdownItem>
                        <DropdownItem onClick={() => logout()}>Sair</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MenuUser